//-----AUTH------
export const SET_LOGIN = "3DVAM_SET_LOGIN";

//-----USER------
export const SET_USER = "3DVAM_SET_USER";
export const SET_USER_DETAIL = "3DVAM_SET_USER_DETAIL";

//-----VPS------
export const SET_VPS_LIST = "3DVAM_SET_VPS_LIST";
export const SET_VPS_DETAIL_LIST = "3DVAM_SET_VPS_DETAIL_LIST";

//-----ACCOUNT------
export const SET_ACCOUNT_LIST = "3DVAM_SET_ACCOUNT_LIST";
export const SET_ACCOUNT_LIST_ALL = "3DVAM_SET_ACCOUNT_LIST_ALL";
export const SET_ACCOUNT_DETAIL_LIST = "3DVAM_SET_ACCOUNT_DETAIL_LIST";
export const SET_ACCOUNT_INFO_LIST = "3DVAM_SET_ACCOUNT_INFO_LIST";
export const SET_ACCOUNT_INFO_DETAIL = "3DVAM_SET_ACCOUNT_INFO_DETAIL";

//-----DAILY BALANCE------
export const SET_BALANCE_LIST = "3DVAM_BALANCE_LIST";

//-----INVOICE------
export const SET_INVOICE_LIST = "3DVAM_INVOICE_LIST";

//-----DAILY PROFIT------
export const SET_PROFIT_LIST = "3DVAM_PROFIT_LIST";
export const SET_PROFIT_ACCOUNT_LIST = "3DVAM_PROFIT_ACCOUNT_LIST";
export const SET_TAKE_PROFIT_ACCOUNT_LIST = "3DVAM_TAKE_PROFIT_ACCOUNT_TAKE";
export const SET_PROFIT_DATE_BYAGENT_LIST = "3DVAM_PROFIT_DATE_BYAGENT_LIST";
export const SET_PROFIT_DATE_ALL_LIST = "3DVAM_PROFIT_DATE_ALL_LIST";
export const SET_PROFIT_ALL_LIST = "3DVAM_PROFIT_ALL_LIST";
export const SET_HISTORY_PROFIT_ACCOUNT_LIST =
  "3DVAM_HISTORY_PROFIT_ACCOUNT_LIST";

//-----DASHBOARD------
export const SET_DAILY_PROFIT_LIST = "3DVAM_DAILY_PROFIT_LIST";
export const SET_MONTHLY_PROFIT_LIST = "3DVAM_MONTHLY_PROFIT_LIST";
export const SET_YEARLY_PROFIT_LIST = "3DVAM_YEARLY_PROFIT_LIST";
export const SET_DAILY_PROFIT_AGENT_LIST = "3DVAM_DAILY_PROFIT_AGENT_LIST";
export const SET_MONTHLY_PROFIT_AGENT_LIST = "3DVAM_MONTHLY_PROFIT_AGENT_LIST";
export const SET_YEARLY_PROFIT_AGENT_LIST = "3DVAM_YEARLY_PROFIT_AGENT_LIST";

//-----DASHBOARD V1------
export const SET_DASHBOARD_V1_DAILY = "3DVAM_DASHBOARD_V1_DAILY";
export const SET_DASHBOARD_V1_MONTHLY = "3DVAM_DASHBOARD_V1_MONTHLY";
export const SET_DASHBOARD_V1_YEARLY = "3DVAM_DASHBOARD_V1_YEARLY";
export const SET_DASHBOARD_V1_EQUITY_BALANCE =
  "3DVAM_DASHBOARD_V1_YEARLY_EQUITY_BALANCE";
export const SET_DASHBOARD_TOTAL_PROFIT = "3DVAM_DASHBOARD_TOTAL_PROFIT";
export const SET_DASHBOARD_TOTAL_WITHDRAWAL =
  "3DVAM_DASHBOARD_TOTAL_WITHDRAWAL";
export const SET_DASHBOARD_TOTAL_DEPOSIT = "3DVAM_DASHBOARD_TOTAL_DEPOSIT";

// -----DASHBOARD V1 MEMBER------
export const SET_DASHBOARD_V1_DAILY_MEMBER = "3DVAM_DASHBOARD_V1_DAILY_MEMBER";
export const SET_DASHBOARD_V1_MONTHLY_MEMBER =
  "3DVAM_DASHBOARD_V1_MONTHLY_MEMBER";
export const SET_DASHBOARD_V1_YEARLY_MEMBER =
  "3DVAM_DASHBOARD_V1_YEARLY_MEMBER";

//-----TRADE------
export const SET_TRADE = "3DVAM_TRADE";

//-----TRADING VIEW------
export const SET_TRADING_VIEW = "3DVAM_TRADING_VIEW";

//-----TP------
export const SET_TAKE_PROFIT = "3DVAM_TAKE_PROFIT";

//-----PERCENTAGE PROFIT-----//
export const SET_PERCENTAGE_PROFIT_MEMBER = "3DVAM_PERCENTAGE_PROFIT_MEMBER";
export const SET_PERCENTAGE_PROFIT_AGENT = "3DVAM_PERCENTAGE_PROFIT_AGENT";
