import { SET_LOGIN, SET_USER, SET_USER_DETAIL } from "../../../constant/type";
import authService from "../../../services/auth";
import tokenService from "../../../token/index";
import baseService from "../../../services/index";
// import {
//   DashboardVpsV1Daily,
//   DashboardVpsV1Monthly,
//   DashboardVpsV1Yearly,
//   DashboardVpsV1EquityBalance,
//   DashboardVpsV1DailyMember,
//   DashboardVpsV1MonthlyMember,
//   DashboardVpsV1YearlyMember,
//   DashboardTotalProfit,
//   DashboardTotalWithdrawal,
//   DashboardTotalDeposit,
// } from "../dashboard/dashboardVpsV1";
// import { AccountListAction } from "../account/accountList";
// import { AccountInfoListAction } from "../account/accountInfoList";

// const fileName = "[ACTION AUTH LOGIN] ";

/* THIS DISPATCH IS USED FOR AUTH */
export const Login = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      authService
        .login(params)
        .then((res) => {
          // console.log(fileName, "response service login ==> \n", res);
          const randomToken = Math.random().toString(36);
          // console.log(fileName, "random token login ==> \n", randomToken);
          tokenService.saveToken(randomToken);
          baseService.setHeader();
          dispatch(actionLogin(res));

          // const agentId = res?.record?.agent?.id || "";
          // const loginType = res?.record?.logintype || 0;
          // if (loginType === 2) {
          //   const params = {
          //     agent_id: agentId,
          //   };
          //   dispatch(AccountListAction(params));
          //   dispatch(DashboardVpsV1Daily(params));
          //   dispatch(DashboardVpsV1Monthly(params));
          //   dispatch(DashboardVpsV1Yearly(params));
          //   dispatch(DashboardVpsV1EquityBalance(params));
          //   dispatch(DashboardTotalProfit(params));
          //   dispatch(DashboardTotalWithdrawal(params));
          //   dispatch(DashboardTotalDeposit(params));
          // } else if (loginType === 3) {
          //   const params = {
          //     infoAccount: res?.record?.infoAccount,
          //   };
          //   dispatch(AccountInfoListAction(params));
          //   dispatch(DashboardVpsV1DailyMember(params));
          //   dispatch(DashboardVpsV1MonthlyMember(params));
          //   dispatch(DashboardVpsV1YearlyMember(params));
          //   dispatch(DashboardTotalProfit(params));
          //   dispatch(DashboardTotalWithdrawal(params));
          //   dispatch(DashboardTotalDeposit(params));
          // } else if (loginType === 4) {
          //   const params = {
          //     infoAccount: res?.record?.infoAccount,
          //   };

          //   dispatch(AccountInfoListAction(params));
          //   dispatch(DashboardVpsV1DailyMember(params));
          //   dispatch(DashboardVpsV1MonthlyMember(params));
          //   dispatch(DashboardVpsV1YearlyMember(params));
          //   dispatch(DashboardTotalProfit(params));
          //   dispatch(DashboardTotalWithdrawal(params));
          //   dispatch(DashboardTotalDeposit(params));
          // }
          dispatch(Profile(res?.record));
          resolve();
        })
        .catch((err) => {
          // console.log(fileName, "error service login ==> \n", err);
          reject(err);
        });
    });
  };
};

export const UserDetail = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      authService
        .loginDetail(params)
        .then((res) => {
          baseService.setHeader();
          dispatch(actionUserDetail(res));
          resolve();
        })
        .catch((err) => {
          dispatch(actionUserDetail(null));
          reject(err);
        });
    });
  };
};

export const Profile = (response) => {
  console.info("response profile: ", response);
  return (dispatch) => {
    dispatch(actionProfile(response));
  };
};

export const LoginReset = () => {
  return (dispatch) => {
    dispatch(actionLogin(null));
  };
};

/* THIS ACTION TO REDUCERS FOR AUTH */
export const actionLogin = (payload) => {
  return {
    type: SET_LOGIN,
    payload: payload,
  };
};

export const actionProfile = (payload) => {
  return {
    type: SET_USER,
    payload: payload,
  };
};

export const actionUserDetail = (payload) => {
  return {
    type: SET_USER_DETAIL,
    payload: payload,
  };
};
